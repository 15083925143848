import React from 'react'
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { graphql, Link } from 'gatsby'
import { ModalRoutingContext } from 'gatsby-plugin-modal-routing'
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { ingredientsBySection } from '../common/sections'
import IngredientTile from '../components/shared/IngredientTile'
import ShareButtons from '../components/shared/ShareButtons'
import AddButton from '../components/shared/Recipes/AddButton'
import scrollTo from 'gatsby-plugin-smoothscroll'

import Layout from '../layouts'

import '../pages/index.scss'
import '../pages/recipes.scss'
interface PageTemplateProps {
  data: {
    site: {
      siteMetadata: {
        title: string
        description: string
        author: {
          name: string
          url: string
        }
      }
    }
    contentfulRecipe: {
      name: string
      description: {
        raw: string
      }
      mainImage: {
        fixed: {
          src: string
        }
      }
      rectangularImage: {
        fixed: {
          src: string
        }
      }
      ingredients: [
        {
          title: string
          ingredient: {
            name: string
            priceCents: number
            section: string
            picture: {
              file: {
                url: string
              }
              description: string
            }
          }
        }
      ]
      steps: [string]
      time: string
      servings: string
    }
  }
}

const structuredData = (recipe, recipeShortDescription) => {
  return {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@type": "Organization",
        "@id": "https://www.elmfood.scom/#organization",
        "name": "ELM Foods",
        "url": "https://www.elmfoods.com.com/",
        "sameAs": ["https://www.facebook.com/elmfoods", "https://www.instagram.com/elmfoods/"],
        "logo": {
          "@type": "ImageObject",
          "url": "https://friends.elmfoods.com/favicons/favicon.ico",
          "width": 32,
          "height": 32,
          "caption": "ELM Foods"
        },
      },
      {
        "@type": "WebSite",
        "@id": "https://www.elmfoods.com/#website",
        "url": "https://www.elmfoods.com/",
        "name": "ELM Foods",
        "description": "Easy plant-based grocery and recipes",
        "publisher": { "@id": "https://www.elmfoods.com/#organization" },
      },
      {
        "@type": "ImageObject",
        "@id": `https://www.elmfoods.com/recipes/${recipe.slug}/#primaryimage`,
        "inLanguage": "en-US",
        "url": recipe.rectangularImage?.file?.url,
        "contentUrl": recipe.rectangularImage?.file?.url,
        "height": recipe.rectangularImage?.file?.details?.image?.height,
        "width": recipe.rectangularImage?.file?.details?.image?.width
      },
      {
        "@type": "WebPage",
        "@id": `https://www.elmfoods.com/recipes/${recipe.slug}/#webpage`,
        "url": `https://www.elmfoods.com/recipes/${recipe.slug}`,
        "name": `${recipe.name} Recipe | ELM Foods`,
        "isPartOf": {
          "@id": "https://www.elmfoods.com.com/#website"
        },
        "primaryImageOfPage": {
          "@id": `https://www.elmfoods.com/recipes/$slug/#primaryimage`
        },
        "description": recipeShortDescription,
        "potentialAction": [{ "@type": "ReadAction", "target": [`https://www.elmfoods.com/recipes/${recipe.slug}`] }]
      },
      {
        "@context": "http://schema.org/",
        "@type": "Recipe",
        "name": recipe.name,
        "author": { "@type": "Person", "name": "Joel Jackson" },
        "description": recipeShortDescription,
        "image": [recipe.rectangularImage?.file?.url],
        "recipeYield": [`${recipe.servings}`],
        //"prepTime": "PT10M", "cookTime": "PT60M",
        "totalTime": `PT${recipe.time}M`,
        "recipeIngredient": (recipe.ingredients || []).map(ingredient => ingredient.title),
        "recipeInstructions": (recipe.steps || []).map((step, index) => {
          return {
            "@type": "HowToStep",
            "text": step,
            "url": `https://www.elmfoods.com/recipes/${recipe.slug}#step-${index}`
          }
        }),
        "@id": `https://www.elmfoods.com/recipes/${recipe.slug}/#recipe`
      }
    ]
  }
}

const PageTemplate: React.SFC<PageTemplateProps> = ({ data }) => {
  const allIngredients = data.contentfulRecipe.ingredients || []
  const sectionedIngredients = ingredientsBySection(allIngredients)
  const recipe = data.contentfulRecipe
  const title = `${recipe.name} Recipe | ELM Foods`
  const shareTitle = recipe.name
  const richDescription = JSON.parse(recipe.description.raw)
  richDescription.content = [richDescription.content[0]]
  const shortDescription = documentToPlainTextString(richDescription)
  const metaImage = {
    src: recipe.rectangularImage?.file?.url,
    height: recipe.rectangularImage?.file?.details?.image?.height,
    width: recipe.rectangularImage?.file?.details?.image?.width
  }
  const deliveryString = Array.from({ length: 40 }, (_, idx) => idx + 1).map(() => "Now delivering for FREE to the Westside").join(" ⚡ ")

  return (
    <ModalRoutingContext.Consumer>
      {({ modal, closeTo }) => (
        <Layout hideHeader={modal} childTitle={title} childDescription={shortDescription} shareTitle={shareTitle} structuredData={structuredData(recipe, shortDescription)} metaImage={metaImage} >
          <div className="container-fluid bg-brand position-relative px-0">
            <div className="row mx-0 border-bottom">
              <div className="col-12 col-md-6 text-center px-0">
                <img className="img-fluid mh-100" src={recipe.rectangularImage?.file?.url} alt={`${recipe.name} image`} />
              </div>
              <div className="col-12 col-md-6 d-flex flex-row align-items-center">
                <div>
                  <h1 className="pb-3 font-weight-bold">{recipe.name}</h1>
                  <div className="mb-1 text-uppercase letter-spaced small">
                    <h4>
                      <span className="font-weight-bold">Makes: </span>
                      {recipe.servings} servings
                  </h4>
                  </div>
                  <div className="text-uppercase letter-spaced small">
                    <h4>
                      <span className="font-weight-bold">Takes: </span>
                      {recipe.time} minutes
                  </h4>
                  </div>
                  <a className={`btn btn-lg btn-primary mt-3 px-5 mx-auto d-print-none mb-5 ${modal ? "d-none" : ""}`}
                    onClick={() => scrollTo('#shop-recipe-ingredients')}>
                    Shop Ingredients
                  </a>
                  <p className="smaller mb-2 text-black-50 d-print-none">Like this recipe? Share it!</p>
                  <div className="d-print-none">
                    <ShareButtons className="d-print-none" title={recipe.name} url={`https://www.elmfoods.com/recipes/${recipe.slug}`} imageUrl={metaImage.src} description={shortDescription} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="container container-print-fluid">
            <div className="row pt-4 font-weight-light print-small">
              <div className="col-12">{recipe.description && renderRichText(recipe.description)}</div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 col-print-12 pt-5">
                <div className="ingredients">
                  <h2 className="mb-4 text-secondary">Ingredients</h2>
                  <div className="row">
                    {sectionedIngredients.map((section, sectionIndex) => {
                      return (
                        <React.Fragment key={`ingredient-section-${section.sectionName}-${sectionIndex}`}>
                          <div className={`col-12 col-print-4 ${sectionIndex != 0 ? 'mt-4' : ''}`}>
                            <h4 className="font-weight-bold">{section.sectionName}</h4>
                            {section.ingredients.map((ingredient, index) => {
                              return (
                                <div className="mb-1 mb-print-0" key={`ingredient-${index}`}>
                                  <label className="font-weight-light print-small mb-print-0" htmlFor={`customCheck${ingredient.title.replace(' ', '-')}`}>
                                    {ingredient.title}
                                  </label>
                                </div>
                              )
                            })}
                          </div>
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 col-print-12 pt-5">
                <h2 className="mb-4">Steps</h2>
                {recipe.steps &&
                  recipe.steps.map((step, index) => {
                    return (
                      <div className="single-preparation-step mt-4" key={`step-${index}`}>
                        <h4 className="mr-3 font-weight-bold d-print-inline">{index >= 9 ? '' : '0'}{index + 1}.</h4>
                        <p className="font-weight-light print-small d-print-inline" dangerouslySetInnerHTML={{ __html: step.replace(/\*([^*]*)\*/g, '<b>$1</b>') }} />
                      </div>
                    )
                  })}
              </div>
            </div>
          </div>
          <div id="shop-recipe-ingredients" className={`d-print-none section shop-ingredients pb-5 mt-5 border-top ${modal ? "d-none" : ""}`}>
            <div className="section bg-info overflow-hidden d-print-none">
              <div className="py-2 d-flex">
                <marquee>
                  <p className="mb-0 font-weight-bold display-4">{deliveryString}</p>
                </marquee>
              </div>
            </div>
            <div className="container">
              <div>
                <div className="row mt-4">
                  <div className="col-12">
                    <h2>Shop Ingredients</h2>
                  </div>
                </div>
                <div className="row pt">
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12 col-md-4">
                        <AddButton recipe={recipe} />
                      </div>
                    </div>
                  </div>
                </div>
                {sectionedIngredients.map((section, sectionIndex) => {
                  if (section.ingredients.every(ingredient => !ingredient.ingredient)) {
                    return
                  }

                  return (
                    <React.Fragment key={`section-${section.sectionName}-${sectionIndex}`}>
                      <div className="row mt-3">
                        <div className="col-12">
                          <h4 className="font-weight-bold">{section.sectionName}</h4>
                        </div>
                      </div>
                      <div className="row">
                        {section.ingredients.map((ingredient) => {
                          const sellable = ingredient.ingredient
                          if (!sellable) {
                            return
                          }

                          sellable.amount = ingredient.amount || 0.01
                          return (
                            <IngredientTile ingredient={sellable} recipes={[recipe]} key={`ingredient-tile-${ingredient.ingredient.sku}`} />
                          )
                        })}
                      </div>
                    </React.Fragment>
                  )
                })}
              </div>
              <div className="row pt-4 d-print-none">
                <div className="col-12 text-center">
                  <Link to="/shop-all-recipes" >
                    Browse Other Recipes
                </Link>
                </div>
              </div>
            </div>
          </div>
        </Layout >
      )
      }
    </ModalRoutingContext.Consumer >
  )
}

export default PageTemplate

export const query = graphql`
  query Recipe($slug: String!) {
    site {
      siteMetadata {
        title
        description
        author {
          name
        }
      }
    }
    contentfulRecipe(slug: { eq: $slug }) {
      name
      slug
      description {
        raw
      }
      mainImage {
        fixed(width: 800) {
          src
        }
      }
      rectangularImage {
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      ingredients {
        title
        amount
        ingredient {
          ... on ContentfulSellableIngredient {
            name
            brand
            priceCents
            sku
            section
            quantityDescription
            slug
            picture {
              fluid(quality: 100, maxWidth:150) {
                src
              }
              description
            }
          }
        }
      }
      steps
      time
      servings
    }
  }
`
// $slug: String!
