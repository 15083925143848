import React from 'react'

import {
  PinterestShareButton,
  PinterestIcon,
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  RedditShareButton,
  RedditIcon
} from 'react-share'

const ShareButtons: React.FC = ({ title, url, imageUrl, description }) => (
  <div>
    <PinterestShareButton media={imageUrl} description={description} url={url} >
      <PinterestIcon size={40} round={true} className="mx-1" />
    </PinterestShareButton>

    <FacebookShareButton url={url} >
      <FacebookIcon size={40} round={true} className="mx-1" />
    </FacebookShareButton>

    <RedditShareButton url={url} title={title} >
      <RedditIcon size={40} round={true} className="mx-1" />
    </RedditShareButton>

    <TwitterShareButton url={url} title={title} via="ElmFoods">
      <TwitterIcon size={40} round={true} className="mx-1" />
    </TwitterShareButton>
  </div>
)

export default ShareButtons
